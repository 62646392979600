
import { defineComponent } from 'vue';
import { Member, MemberSession } from '@stytch/vanilla-js';

export default defineComponent({
  name: 'StytchProfile',
  data() {
    return {
      user: null as Member | null,
      session: null as MemberSession | null
    };
  },
  created() {
    this.user = this.$stytch.member.getSync();
    this.session = this.$stytch.session.getSync();
  },
  methods: {
    logout() {
      this.$stytch.session.revoke();
    }
  }
});
