
import { defineComponent } from 'vue';
import { UnsubscribeFunction } from '@stytch/vanilla-js/b2b';
import { RouteNames } from '@/router/routes';

export default defineComponent({
  name: 'App',

  data() {
    return {
      unsubscribe: null as UnsubscribeFunction | null
    };
  },

  created() {
    this.unsubscribe = this.$stytch.session.onChange((session) => {
      if (session) {
        if (this.$route.name !== RouteNames.PROFILE) {
          this.$router.push({ name: RouteNames.PROFILE });
        }
      } else {
        this.$router.push({ name: RouteNames.LOGIN });
      }
    });
  },

  beforeUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
});
