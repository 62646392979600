import Vue from 'vue';
import Vuex from 'vuex';
import Config from '@/store/config';

Vue.use(Vuex);

const modules = {
  Config,
};

const store = new Vuex.Store({
  modules,
});

export default store;
