
import { defineComponent } from 'vue';
import { B2BProducts, StytchB2BUIConfig, AuthFlowType, StytchError } from '@stytch/vanilla-js/b2b';

const REDIRECT_URL = `${window.location.origin}/`;
const config: StytchB2BUIConfig = {
  authFlowType: AuthFlowType.Discovery,
  products: [B2BProducts.emailMagicLinks],
  sessionOptions: {
    sessionDurationMinutes: 60
  },
  emailMagicLinksOptions: {
    loginRedirectURL: REDIRECT_URL,
    signupRedirectURL: REDIRECT_URL,
    discoveryRedirectURL: REDIRECT_URL,
  }
};

const styles = {
  container: {
    width: '100%'
  },
  buttons: {
    primary: {
      backgroundColor: '#4A37BE',
      borderColor: '#4A37BE'
    }
  }
};

export default defineComponent({
  name: 'LoginPage',
  data() {
    return {
      errorMessage: ''
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$stytch.mount({
        elementId: '#stytch-sdk',
        styles,
        config,
        callbacks: {
          onEvent: () => {},
          onError: (error: StytchError) => {
            if (error) this.errorMessage = `An error occurred: ${error.message}`;
          }
        },
      });
    });
  }
});
