
import { defineComponent } from 'vue';
import AuthLogin from '@/components/auth/LoginPage.vue';

export default defineComponent({
  name: 'LoginView',
  components: {
    AuthLogin,
  }
});
