
import { defineComponent } from 'vue';
import { Member } from '@stytch/vanilla-js';
import { createMember } from '@/api';

export default defineComponent({
  name: 'CreateMember',
  data() {
    return {
      emailAddress: '',
      member: null as Member | null,
      feedbackMsg: ''
    };
  },
  mounted() {
    this.member = this.$stytch.member.getSync();
  },
  methods: {
    async submit() {
      if (!this.member) return;

      const payload = {
        organization_id: this.member.organization_id,
        email_address: this.emailAddress,
      };
      try {
        await createMember(payload);
        this.feedbackMsg = 'Created member';
      } catch (err: unknown) {
        this.feedbackMsg = 'Failed to create member';
      }
    }
  }
});
