import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import LoginView from '@/views/LoginView.vue';
import ProfileView from '@/views/ProfileView.vue';
import { RouteNames } from '@/router/routes';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: RouteNames.LOGIN,
    component: LoginView,
  }, {
    path: '/profile',
    name: RouteNames.PROFILE,
    component: ProfileView,
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
