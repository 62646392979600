import Vue, { PluginObject } from 'vue';
import commonUi from '@momnt-technologies/common-ui';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import StytchClient from './plugins/stytch';

Vue.config.productionTip = false;
Vue.use(commonUi as PluginObject<unknown>);

(async () => {
  // Config needs to load before we can send out any other API requests.
  const config = await store.dispatch('Config/updateConfig');
  Vue.use(StytchClient, { token: config.STYTCH_PUBLIC_TOKEN });

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
})();
