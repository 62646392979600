
import { defineComponent } from 'vue';
import CreateMember from '@/components/CreateMember.vue';
import StytchProfile from '@/components/StytchProfile.vue';

export default defineComponent({
  name: 'ProfileView',
  components: {
    CreateMember,
    StytchProfile
  }
});
